import Modal from "react-bootstrap/Modal";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { Col, Row } from "react-bootstrap";

const StyledModal = styled(Modal)`
  .modal-content {
    border-radius: 10px;
  }
`;

// Styled component for the clickable columns
const StyledCol = styled(Col)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  border: 1px solid #ddd;
  border-radius: 8px;
  margin: 5px;
  cursor: pointer;
  transition: transform 0.2s, background-color 0.2s, box-shadow 0.2s;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  color: #333;
  flex-grow: 1;

  &:hover {
    background-color: #f0f0f0;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    transform: translateY(-3px);
  }

  &:active {
    background-color: #e0e0e0;
    box-shadow: inset 0px 4px 8px rgba(0, 0, 0, 0.1);
    transform: translateY(0);
  }
`;

const EmailModal = (props) => {
  const { user: profile } = useSelector((state) => state.userDetails);

  const handleAppSelection = (appName) => {
    if (props.onChange) {
      props.onChange(appName);
    }
  };

  return (
    <StyledModal
      {...props}
      backdrop="static"
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton={props.closeButton}>
        <Modal.Title id="contained-modal-title-vcenter">Choose Email App</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row className="d-flex justify-content-between">
          <Col>
            <h3>To complete your proposal submission, include a message in your email body and attach the proposal document.</h3>
          </Col>
        </Row>
        <Row className="d-flex justify-content-between">
          <StyledCol md={2} xs={6} onClick={() => handleAppSelection("default")}>
            Default
          </StyledCol>
          <StyledCol md={2} xs={6} onClick={() => handleAppSelection("gmail")}>
            Gmail
          </StyledCol>
          <StyledCol md={2} xs={6} onClick={() => handleAppSelection("outlook")}>
            Outlook
          </StyledCol>
          <StyledCol md={2} xs={6} onClick={() => handleAppSelection("yahoo")}>
            Yahoo Mail
          </StyledCol>
        </Row>
      </Modal.Body>
    </StyledModal>
  );
};

EmailModal.defaultProps = {
  closeButton: true,
};

export default EmailModal;
