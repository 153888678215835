import { combineReducers } from "redux";
import auth from "./authentication/authentication.reducer";
import projects from "./projects/projects.reducer";
import comments from "./projects/comments.reducer";
import {
  projectTypeDetailReducer,
  projectTypeReducer,
  proposalCreateReducer,
  proposalDetailReducer,
  proposalDownloadReducer,
  proposalListReducer,
  proposalSubscriptionReducer,
  proposalUpdateReducer,
  proposalValuesReducer,
  proposalSectionsReducer,
  contactRolesDetailsReducer,
  proposalDocumentUploadReducer,
  fetchProposalDocumentReducer,
  proposalTemplatesReducer,
  userProjectTypeReducer,
} from "./proposal/proposalReducer";
import {
  createPageViewReducer,
  fetchFavoriteReducer,
  listPageViewReducer,
  manageUserBillingReducer,
  onboardingReducer,
  PageFavoritesReducer,
  passwordResetConfirmReducer,
  passwordResetReducer,
  sendDomainVerification,
  userDetailsReducer,
  userSetFreeModeCount,
  userSetFreeModeSqFtCount,
  userUpdateProfileReducer,
} from "./users/userReducer";
import {
  bidAmountPricingReducer,
  bidAmountValuesReducer,
  cleanupEstimatesCreateReducer,
  cleanupEstimatesDetailReducer,
  cleanupEstimatesListReducer,
  cleanupEstimatesUpdateReducer,
  costProfitValuesReducer,
  projectTypePricingListReducer,
  stateLaborPricingListReducer,
  stateLaborPricingReducer,
  calculationInfoReducer,
  calculationInfoSavedReducer,
} from "./mortgageCalculator/mortgageCalculatorReducer";
import {
  gcQualifyCompanyListReducer,
  planRoomUpdateReducer,
  regionListReducer,
  preQualifyUpdateReducer,
} from "./gcQualify/gcQualifyReducer";
import {
  companyBasicInfoUpdateReducer,
  companyFormsProgressReducer,
  companyBasicInfoReducer,
  companyContinueInfoReducer,
  companyContinueInfoUpdateReducer,
  companySocialInfoReducer,
  companySocialInfoUpdateReducer,
  companyOrgDetailsReducer,
  companyOrgDetailsUpdateReducer,
  companyHistoryReducer,
  companyHistoryUpdateReducer,
  companyCurrentWorkReducer,
  companyCurrentWorkUpdateReducer,
  companyWorkReducer,
  companyWorkUpdateReducer,
  companyInsuranceReducer,
  companyInsuranceUpdateReducer,
  companySafetyReducer,
  companySafetyUpdateReducer,
  companyFinanceReducer,
  companyFinanceUpdateReducer,
  companyLegalReducer,
  companyLegalUpdateReducer,
  companyShippingReducer,
  companyShippingUpdateReducer,
  companySupplierReducer,
  companySupplierUpdateReducer,
  projectTypesReducer,
} from "./companyDetails/companyDetailsReducer";
import { pdfReducer, statementValuesReducer } from "./pdf/pdf.reducer";
import {
  contactListReducer,
  downloadContactListReducer,
  downloadListReducer,
} from "./contact/contactReducer";
import { listEmailTemplateReducer } from "./emailTemplate/emailTemplateReducer";
import {
  SiteVisitActionReducer,
  SiteVisitDetailsReducer,
  SiteVisitHeadingReducer,
  SiteVisitListReducer,
  SiteVisitReducer,
} from "./siteVisit/siteVisitReducer";
import referralReducer from "./referral/referralReducer";

export default combineReducers({
  auth,
  pdf: pdfReducer,
  statementValues: statementValuesReducer,
  projects,
  comments,
  proposalCreate: proposalCreateReducer,
  proposalDownload: proposalDownloadReducer,
  proposalList: proposalListReducer,
  proposalDetail: proposalDetailReducer,
  proposalValues: proposalValuesReducer,
  proposalSections: proposalSectionsReducer,
  progressInfo: companyFormsProgressReducer,
  projectTypes: projectTypesReducer,
  basicInfoUpdate: companyBasicInfoUpdateReducer,
  basicInfo: companyBasicInfoReducer,
  continueInfoUpdate: companyContinueInfoUpdateReducer,
  continueInfo: companyContinueInfoReducer,
  socialInfoUpdate: companySocialInfoUpdateReducer,
  socialInfo: companySocialInfoReducer,
  orgDetailsInfo: companyOrgDetailsReducer,
  orgDetailsUpdate: companyOrgDetailsUpdateReducer,
  historyInfo: companyHistoryReducer,
  historyUpdate: companyHistoryUpdateReducer,
  currentWorkInfo: companyCurrentWorkReducer,
  currentWorkUpdate: companyCurrentWorkUpdateReducer,
  workInfo: companyWorkReducer,
  workUpdate: companyWorkUpdateReducer,
  insuranceInfo: companyInsuranceReducer,
  insuranceUpdate: companyInsuranceUpdateReducer,
  safetyInfo: companySafetyReducer,
  safetyUpdate: companySafetyUpdateReducer,
  financeInfo: companyFinanceReducer,
  financeUpdate: companyFinanceUpdateReducer,
  supplierInfo: companySupplierReducer,
  supplierUpdate: companySupplierUpdateReducer,
  legalInfo: companyLegalReducer,
  legalUpdate: companyLegalUpdateReducer,
  shippingInfo: companyShippingReducer,
  shippingUpdate: companyShippingUpdateReducer,
  userDetails: userDetailsReducer,
  userUpdateProfile: userUpdateProfileReducer,
  proposalSubscription: proposalSubscriptionReducer,
  projectTypeList: projectTypeReducer,
  userProjectTypeList: userProjectTypeReducer,
  proposalTemplates: proposalTemplatesReducer,
  projectTypeDetail: projectTypeDetailReducer,
  projectUpdate: proposalUpdateReducer,
  passwordReset: passwordResetReducer,
  passwordResetConfirm: passwordResetConfirmReducer,
  bidAmountValues: bidAmountValuesReducer,
  bidAmountPricing: bidAmountPricingReducer,
  stateLaborPricing: stateLaborPricingReducer,
  stateLaborPricingList: stateLaborPricingListReducer,
  projectTypePricingList: projectTypePricingListReducer,
  costProfitValues: costProfitValuesReducer,
  cleanUpEstimateCreate: cleanupEstimatesCreateReducer,
  cleanUpEstimateUpdate: cleanupEstimatesUpdateReducer,
  cleanUpEstimateList: cleanupEstimatesListReducer,
  cleanUpEstimateDetail: cleanupEstimatesDetailReducer,
  regionList: regionListReducer,
  gcQualifyCompanyList: gcQualifyCompanyListReducer,
  userFreeModeCount: userSetFreeModeCount,
  userFreeModeSqFtCount: userSetFreeModeSqFtCount,
  manageUserBilling: manageUserBillingReducer,
  planRoomUpdate: planRoomUpdateReducer,
  preQualifyUpdate: preQualifyUpdateReducer,
  calculationInfo: calculationInfoReducer,
  calculationSaved: calculationInfoSavedReducer,
  domainVerification: sendDomainVerification,
  contactRolesDetails: contactRolesDetailsReducer,
  contacts: contactListReducer,
  emailTemplates: listEmailTemplateReducer,
  onboarding: onboardingReducer,
  createPageView: createPageViewReducer,
  listPageView: listPageViewReducer,
  ListFavoritePages: PageFavoritesReducer,
  fetchPageFavorite: fetchFavoriteReducer,
  proposalDocumentUpload: proposalDocumentUploadReducer,
  siteVisit: SiteVisitReducer,
  siteVisitHeading: SiteVisitHeadingReducer,
  siteVisitAction: SiteVisitActionReducer,
  siteVistList: SiteVisitListReducer,
  siteVisitDetails: SiteVisitDetailsReducer,
  downloadContacts: downloadContactListReducer,
  proposalDocumentDetail: fetchProposalDocumentReducer,
  referral: referralReducer,
});
