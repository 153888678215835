import React, { useEffect, useState } from "react";
import {
  Accordion,
  Alert,
  Col,
  FloatingLabel,
  Form,
  Row,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import "react-intl-tel-input/dist/main.css";
import { StyledAccordionHeader, StyledForm } from "./CalculatorForm.styled";
import Select from "react-select";
import {
  BID_AMOUNT_SET_VALUES,
  COST_PROFIT_SET_VALUES,
} from "../../store/constants/mortgageConstant";
import {
  getBidAmountPricingDetail,
  getProjectTypePricingList,
  getStateLaborPricingDetail,
  getStateLaborPricingList,
} from "../../store/actions/mortgage_calculator/mortgage_calculator.actions";
import Spinner from "../spinner/Spinner";
import {
  Link,
  useLocation,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { isSubscriptionActive } from "../../utils/helpers/helper";
import {
  setFreeModeAction,
  setFreeModeSqFt,
} from "../../store/actions/users/users.actions";
import {
  USER_SET_FREE_MODE_SQFT_SUCCESS,
  USER_SET_FREE_MODE_SUCCESS,
} from "../../store/constants/userConstants";
import CloseIcon from "../../assets/icons/CloseIcon";
import styled from "styled-components";
import ToolTip from "../tooltip/ToolTip";
import { useRef } from "react";
import { useFeatureAccess } from "../../hooks/useFeatureAccess";

const StyledFloatingLabel = styled(FloatingLabel)`
  label {
    z-index: 0;
  }
`;

const phaseData = [
  { value: "rough", label: "Rough", key: 1, cost: 105 },
  { value: "final", label: "Final", key: 2, cost: 175 },
  { value: "fluff", label: "Fluff", key: 3, cost: 70 },
];

const notSureData = [
  { value: 2532, label: "Extra Small" },
  { value: 13000, label: "Small" },
  { value: 67385, label: "Medium" },
  { value: 250650, label: "Large" },
  { value: 730000, label: "Extra Large" },
];
// const storiesData = [
//   { name: 1, value: 0 },
//   { name: 2, value: 0.01 },
//   { name: 3, value: 0.02 },
//   { name: 4, value: 0.03 },
//   { name: 5, value: 0.04 },
// ];

const projectTypePricingPricePerDay = [
  { name: "Multifamily-Residential" },
  { name: "Residence Home" },
  { name: "Senior living Retirement" },
  { name: "Mixed use" },
];

const CalculatorBidAmount = ({
  handleSetLoginModal,
  handleSetPaymentModalShow,
  bidAmount,
  noOfDaysExpected,
  handleBidAmountChange,
  setFormTouched,
  handlePricePerDay,
}) => {
  const { error: errorForm } = useSelector(
    (state) => state.cleanUpEstimateCreate
  );
  const { stateLabors } = useSelector((state) => state.stateLaborPricingList);
  const { hasFeatureAccess, enforceAccess, hasSqftFeatureAccess } =
    useFeatureAccess("Calculator");
  const {
    loading: stateLaborLoader,
    one_day_work,
    average_labor_rate,
  } = useSelector((state) => state.stateLaborPricing);
  const { projectTypePricing } = useSelector(
    (state) => state.projectTypePricingList
  );
  const [selectedKey, setSelectedKey] = useState(true);
  const [showCalculatorNote, setShowCalculatorNote] = useState(true);

  const values = useSelector((state) => state.bidAmountValues);
  const { loading } = useSelector((state) => state.cleanUpEstimateDetail);
  const { user } = useSelector((state) => state.auth);
  const { free_mode_count } = useSelector((state) => state.userFreeModeCount);
  const { free_mode_sqft_count } = useSelector(
    (state) => state.userFreeModeSqFtCount
  );
  const { user: profile } = useSelector((state) => state.userDetails);
  const costValues = useSelector((state) => state.costProfitValues);
  let params = useParams();
  const { id } = params;
  const dispatch = useDispatch();
  const price_id = process.env.REACT_APP_CALC_APP;
  const estimatedDaysWorkingRef = useRef(null);
  const noOfLivingUnitRef = useRef(null);
  const buildingSqftRef = useRef(null);
  let [searchParams] = useSearchParams();
  const location = useLocation();

  const hideLivingUnit = [
    "Senior living Retirement",
    "Residence Home",
    "Multifamily-Residential",
    "Mixed use",
    "Mixed Use",
  ].includes(values.project_type);

  const defaultProjectType = (projectType, square_foot) => {
    const projectTypeList = projectTypePricing.map(
      (projectType) => projectType.project_type
    );
    if (!projectTypeList.includes(projectType) && square_foot !== "") {
      return "Any Type General Cleaning";
    }

    return projectType;
  };
  const transformProjectType = (projectType, square_foot) => {
    const parkingTypes = [
      "Bathroom cleaning",
      "Clean Room",
      "Duct clean",
      "Transit station",
      "Data center",
    ];
    const communityPublicTypes = ["Miscellaneous", "Zoo"];
    const Military = ["Military installation", "Military Installation"];

    if (parkingTypes.includes(projectType)) {
      return "Industrial";
    } else if (communityPublicTypes.includes(projectType)) {
      return "Any Type General Cleaning";
    } else if (projectType === "Renovation Addition") {
      return "Tenant Fit-Out";
    } else if (Military.includes(projectType)) {
      return "Military Installation";
    } else if (projectType === "") {
      return "Any Type General Cleaning";
    }

    return defaultProjectType(projectType, square_foot);
  };

  const handleLivingUnit = (livingUnit) => {
    if (livingUnit) {
      setFormTouched(true); // Assuming setFormTouched is part of your component's state
      return {
        use_living_unit_pricing: true,
        use_number_of_days: false,
        phase: "rough_final_fluff",
        totalSumOfPhases: 350,
        price_per_sqft: false,
      };
    }
    return {};
  };

  useEffect(() => {
    let square_foot = searchParams.get("square_foot");
    let project_type = searchParams.get("project_type");
    const state = searchParams.get("state");
    const project_name = searchParams.get("project_name");
    const living_unit = searchParams.get("living_unit");
    const url_slug = searchParams.get("url_slug");
    const davis = searchParams.get("davis");
    const union = searchParams.get("union");

    if (square_foot || state || project_type || project_name || living_unit) {
      project_type = transformProjectType(project_type, square_foot);

      if (living_unit) {
        if (
          ["Multifamily-Residential", "Multi-family residential"].includes(
            project_type
          )
        ) {
          project_type = "Multifamily-Residential";
        } else if (["Mixed use", "Mixed Use"].includes(project_type)) {
          project_type = "Mixed use";
        }
      }

      const livingUnitPayload = handleLivingUnit(living_unit);
      if (square_foot === "") {
        square_foot = 0;
        dispatch({
          type: COST_PROFIT_SET_VALUES,
          payload: { unknown_sqft_size: true },
        });
      }

      dispatch({
        type: BID_AMOUNT_SET_VALUES,
        payload: {
          square_foot,
          state,
          project_type,
          project_name,
          living_unit,
          url_slug,
          davis_checked: davis == "true",
          union_checked: union == "true",
          ...livingUnitPayload,
        },
      });
    }

    let saved = localStorage.getItem(`pressure_wash_price_${user?.id}`);
    if (saved)
      dispatch({
        type: BID_AMOUNT_SET_VALUES,
        payload: { pressure_wash_price: saved },
      });
  }, [projectTypePricing, location.search, dispatch]);

  const state =
    localStorage.getItem("state") &&
    searchParams.get("state") == null &&
    id == null
      ? localStorage.getItem("state")
      : values?.state;
  useEffect(() => {
    if (values?.project_type !== "" && values?.phase !== "") {
      dispatch(getBidAmountPricingDetail(values?.project_type, values?.phase));
    }
    if (values?.project_type !== "" && values?.phase !== "" && state !== "") {
      dispatch(getStateLaborPricingDetail(state));
    }
  }, [dispatch, values?.phase, values?.project_type, values?.state]);

  useEffect(() => {
    if (projectTypePricing.length === 0) {
      dispatch(getProjectTypePricingList());
    }
  }, [dispatch, projectTypePricing.length]);

  useEffect(() => {
    if (stateLabors.length === 0) {
      dispatch(getStateLaborPricingList());
    }
  }, [dispatch, stateLabors.length]);

  const handleChange = (evt) => {
    const { name, value: newValue } = evt.target;

    if (
      !user &&
      (name === "square_foot" || name === "notes" || name === "project_name")
    ) {
      handleSetLoginModal(evt);
      return;
    }
    if (name === "square_foot" && costValues.unknown_sqft_size) {
      return;
    }
    if (name === "square_foot") {
      dispatch(setFreeModeSqFt());
      dispatch({
        type: USER_SET_FREE_MODE_SQFT_SUCCESS,
        payload: free_mode_sqft_count + 1,
      });
    }

    setFormTouched(true);
    if (!hasSqftFeatureAccess) {
      enforceAccess();
      return;
    }

    dispatch({ type: BID_AMOUNT_SET_VALUES, payload: { [name]: newValue } });
    if (name === "projectType" && newValue !== "" && values?.phase !== "") {
      dispatch(getBidAmountPricingDetail(newValue, values?.phase));
    }
    handleBidAmountChange();
    if (name == "pressure_wash_price")
      localStorage.setItem(`pressure_wash_price_${user?.id}`, newValue);
  };
  const handleClick = (evt) => {
    dispatch({
      type: BID_AMOUNT_SET_VALUES,
      payload: { emergency_pricing: evt.target.checked },
    });
  };
  const handleNoStoriesCheck = (evt) => {
    dispatch({
      type: BID_AMOUNT_SET_VALUES,
      payload: { no_stories_check: evt.target.checked },
    });
  };
  const handleUseUnitPricingCheck = (evt) => {
    const { name } = evt.target;
    setFormTouched(true);

    dispatch({
      type: BID_AMOUNT_SET_VALUES,
      payload: {
        [name]: evt.target.checked,
        use_living_unit_pricing: true,
        phase: "rough_final_fluff",
        totalSumOfPhases: 350,
        price_per_sqft: false,
        sqft_and_units: false,
      },
    });

    dispatch({
      type: COST_PROFIT_SET_VALUES,
      payload: { use_number_of_days: false },
    });
  };

  const handleAddFlooringCheck = (evt) => {
    const { name } = evt.target;
    setFormTouched(true);

    dispatch({
      type: BID_AMOUNT_SET_VALUES,
      payload: {
        [name]: evt.target.checked,
      },
    });
  };

  const handleLivingUnitPricingCheck = (evt) => {
    const { name } = evt.target;

    dispatch({
      type: BID_AMOUNT_SET_VALUES,
      payload: { [name]: evt.target.checked },
    });
    // const newProjectType = projectTypePricing?.filter(
    //   (project) =>
    //     project.project_type === "Multifamily-Residential" ||
    //     project.project_type === "Senior living Retirement" ||
    //     project.project_type === "Residence Home" ||
    //     project.project_type === "Mixed use"
    // );

    if (evt.target.checked) {
      // dispatch({
      //   type: "PROJECT_TYPE_PRICING_LIST_SUCCESS",
      //   payload: newProjectType,
      // });
      dispatch({
        type: COST_PROFIT_SET_VALUES,
        payload: { use_number_of_days: false },
      });
      dispatch({
        type: BID_AMOUNT_SET_VALUES,
        payload: { phase: "rough_final_fluff" },
      });
      dispatch({
        type: BID_AMOUNT_SET_VALUES,
        payload: { totalSumOfPhases: 350 },
      });
      dispatch({
        type: BID_AMOUNT_SET_VALUES,
        payload: { price_per_sqft: false },
      });
    } else {
      dispatch(getProjectTypePricingList());
    }

    if (!evt.target.checked && !costValues?.use_number_of_days) {
      dispatch({
        type: "BID_AMOUNT_SET_VALUES",
        payload: { price_per_sqft: true },
      });
    }
    setTimeout(() => {
      if (evt.target.checked) {
        noOfLivingUnitRef?.current?.focus();
      }
    }, 1000);
  };
  const handleScrubbingPricingCheck = (evt) => {
    dispatch({
      type: BID_AMOUNT_SET_VALUES,
      payload: { scrubbing_pricing: evt.target.checked },
    });
  };

  const handleCheckBox = (evt) => {
    dispatch({
      type: BID_AMOUNT_SET_VALUES,
      payload: { [evt.target.name]: evt.target.checked },
    });
  };

  const handleSelect = (items) => {
    setFormTouched(true);
    const phases = items.map((item) => item.value).join("_");
    let totalSum = 0;
    items?.forEach((item) => {
      totalSum += item.cost;
    });

    dispatch({
      type: BID_AMOUNT_SET_VALUES,
      payload: {
        phase: phases,
        totalSumOfPhases: totalSum,
        use_price_unit_field: false,
      },
    });

    if (values?.project_type !== "" && phases !== "") {
      dispatch(getBidAmountPricingDetail(values?.project_type, phases));
    }
  };
  const handleNotSureField = (evt) => {
    const { value: newValue } = evt.target;
    if (newValue === "" && values?.phase !== "") {
      dispatch(getBidAmountPricingDetail(newValue, values?.phase));
    }
    dispatch({
      type: BID_AMOUNT_SET_VALUES,
      payload: { square_foot: newValue, not_sure: newValue },
    });
  };
  const handleSelectState = (evt) => {
    const { value } = evt.target;
    dispatch({
      type: BID_AMOUNT_SET_VALUES,
      payload: { state: value, use_price_unit_field: false },
    });

    if (value !== "") {
      handlePricePerDay(values?.one_day_work, false);
      localStorage.setItem("state", value);
      dispatch(getStateLaborPricingDetail(value));
    }
  };

  const handleSquareFootChange = (evt) => {
    if (!user) {
      handleSetLoginModal(evt);
    } else {
      if (costValues.unknown_sqft_size) {
        return;
      }
      const { name, value: newValue } = evt.target;
      setFormTouched(true);
      dispatch({ type: BID_AMOUNT_SET_VALUES, payload: { [name]: newValue } });
    }
  };
  const handleNumberOfDaysCheck = (evt) => {
    const { name } = evt.target;
    setFormTouched(true);

    dispatch({
      type: COST_PROFIT_SET_VALUES,
      payload: { [name]: evt.target.checked },
    });
    dispatch({
      type: BID_AMOUNT_SET_VALUES,
      payload: { use_living_unit_pricing: false, sqft_and_units: false },
    });
    const noOfDaysExpected = (bidAmount / one_day_work).toFixed(1);
    dispatch({
      type: COST_PROFIT_SET_VALUES,
      payload: { noOfDaysExpected: noOfDaysExpected },
    });

    dispatch({
      type: "BID_AMOUNT_SET_VALUES",
      payload: { price_per_sqft: false },
    });

    if (!evt.target.checked && !values?.use_living_unit_pricing) {
      dispatch({
        type: "BID_AMOUNT_SET_VALUES",
        payload: { price_per_sqft: true },
      });
    }
    setTimeout(() => {
      if (evt.target.checked) {
        estimatedDaysWorkingRef?.current?.focus();
      }
    }, 1000);
  };

  const handlePricePerSqFt = (evt) => {
    const { name } = evt.target;
    setFormTouched(true);
    if (!evt.target.checked && !values?.use_living_unit_pricing) {
      dispatch({
        type: BID_AMOUNT_SET_VALUES,
        payload: { [name]: true },
      });
    }
    dispatch({
      type: BID_AMOUNT_SET_VALUES,
      payload: { [name]: evt.target.checked },
    });
    dispatch({
      type: BID_AMOUNT_SET_VALUES,
      payload: { use_living_unit_pricing: false },
    });
    dispatch({
      type: COST_PROFIT_SET_VALUES,
      payload: { use_number_of_days: false },
    });
    setTimeout(() => {
      if (evt.target.checked) {
        buildingSqftRef?.current?.focus();
      }
    }, 1000);
  };

  const handleNumberOfDaysExpected = (evt) => {
    const { value: newValue } = evt.target;
    dispatch({
      type: COST_PROFIT_SET_VALUES,
      payload: { noOfDaysExpected: newValue },
    });
  };

  const handleCalculatorNote = (evt) => {
    dispatch({
      type: BID_AMOUNT_SET_VALUES,
      payload: { notes: evt.target.value },
    });
  };

  const handleUnknownSqftSize = (evt) => {
    dispatch({
      type: COST_PROFIT_SET_VALUES,
      payload: { unknown_sqft_size: evt.target.checked },
    });

    if (evt.target.checked) {
      dispatch({
        type: BID_AMOUNT_SET_VALUES,
        payload: { square_foot: 0 },
      });
    }
  };

  const handleSqftAndUnit = (evt) => {
    dispatch({
      type: BID_AMOUNT_SET_VALUES,
      payload: {
        sqft_and_units: evt.target.checked,
        price_per_sqft: evt.target.checked,
        use_living_unit_pricing: evt.target.checked,
      },
    });

    if (!evt.target.checked)
      dispatch({
        type: BID_AMOUNT_SET_VALUES,
        payload: { price_per_sqft: true },
      });
    else {
      dispatch({
        type: COST_PROFIT_SET_VALUES,
        payload: { use_number_of_days: false },
      });
      dispatch({
        type: BID_AMOUNT_SET_VALUES,
        payload: {
          phase: "rough_final_fluff",
          totalSumOfPhases: 350,
        },
      });
    }
  };

  const handleShowCalculatorNote = () => {
    setShowCalculatorNote(!showCalculatorNote);
  };
  const handleNoOfStoriesResult = (value) => {
    let v = parseInt(value) ? parseInt(value) : 0;
    dispatch({
      type: BID_AMOUNT_SET_VALUES,
      payload: { no_of_stories_result: v },
    });
  };
  const handleNoOfStories = (value) => {
    dispatch({
      type: BID_AMOUNT_SET_VALUES,
      payload: { no_stories: value },
    });
  };

  useEffect(() => {
    let value = 0;
    if (values?.use_living_unit_pricing) {
      value =
        values?.no_stories === 1
          ? 0
          : parseInt(
              values?.no_stories * 100 * 13 * parseInt(values?.living_unit)
            );
    } else {
      value =
        values?.no_stories === 1
          ? 0
          : parseInt((values?.no_stories * values?.square_foot).toFixed(0));
    }
    dispatch({
      type: BID_AMOUNT_SET_VALUES,
      payload: { no_of_stories_result: value },
    });
  }, [values?.no_stories, values?.living_unit]);

  useEffect(() => {
    if (values?.use_living_unit_pricing && !values?.sqft_and_units) {
      dispatch({
        type: COST_PROFIT_SET_VALUES,
        payload: { use_number_of_days: false },
      });
      dispatch({
        type: BID_AMOUNT_SET_VALUES,
        payload: { price_per_sqft: false },
      });
    }
  }, [values?.use_living_unit_pricing]);

  const selectedName = selectedKey ? "Advanced" : "Simple";

  return (
    <StyledForm className="">
      {errorForm && (
        <Alert variant="danger">
          <Alert.Heading>Required Fields!</Alert.Heading>
          <ul>
            {Object.keys(errorForm).map((error, index) => {
              return (
                <li key={index}>
                  {error} {errorForm[error].toString()}
                </li>
              );
            })}
          </ul>
        </Alert>
      )}
      {loading && id ? (
        <div className="text-center">
          <Spinner />
        </div>
      ) : (
        <>
          <p className="text-start fw-bolder">
            Estimate your cleaning price based on building size & other factors
          </p>
          {showCalculatorNote ? (
            <Row className="my-5">
              <Form.Group as={Col} controlId="formGridProject Type">
                <FloatingLabel
                  controlId="notes"
                  label="Enter project details to help you create your estimate"
                  className="mb-3"
                >
                  <CloseIcon
                    width="12"
                    height="12"
                    className="me-3 cursor-pointer"
                    fill="#9EC3E8"
                    style={{ position: "absolute", right: "2%", top: "11%" }}
                    handleClick={handleShowCalculatorNote}
                  />
                  <Form.Control
                    as="textarea"
                    name="notes"
                    className="form-control mt-n3"
                    value={values?.notes}
                    onBlur={handleCalculatorNote}
                    onChange={handleChange}
                    style={{ height: "80px", color: "black" }}
                  />
                </FloatingLabel>
              </Form.Group>
            </Row>
          ) : (
            <p
              className="text-start text-primary cursor-pointer"
              onClick={handleShowCalculatorNote}
            >
              click here show project details
            </p>
          )}
          <Row className="my-5">
            <Form.Group as={Col} controlId="projectName">
              <StyledFloatingLabel
                // <FormLabel
                id="estimate-name-label"
                controlId="projectName"
                label="Estimate name"
                className="mb-3"
              >
                <Form.Control
                  name="project_name"
                  type="text"
                  value={values?.project_name}
                  onChange={handleChange}
                />
              </StyledFloatingLabel>
            </Form.Group>
          </Row>
          <Row className="my-5">
            <Form.Group as={Col} controlId="formGridProject Type">
              <FloatingLabel
                controlId="squarefootsize"
                label="Building Sq. Ft size"
                className="mb-3"
              >
                <Form.Control
                  name="square_foot"
                  type="number"
                  className="form-control mt-n3"
                  value={values?.square_foot}
                  onBlur={handleSquareFootChange}
                  onChange={handleChange}
                  disabled={costValues.unknown_sqft_size}
                  readOnly={costValues.unknown_sqft_size}
                  style={
                    values?.sqft_and_units
                      ? { boxShadow: "0 0 5px 0 darkgray" }
                      : {}
                  }
                  ref={buildingSqftRef}
                />
              </FloatingLabel>
            </Form.Group>
            <Form.Group
              as={Col}
              md={4}
              className="mb-3 checkbox m-0 d-flex"
              controlId="do_not_know_sqft_size"
            >
              <Form.Check
                type="checkbox"
                name="unknown_sqft_size"
                label="I don't know sq.ft size"
                className="mb-4"
                checked={costValues?.unknown_sqft_size}
                onChange={handleUnknownSqftSize}
              />
              <span>
                <ToolTip information="The square footage will be automatically set to 0" />
              </span>
            </Form.Group>

            {/* <Form.Group as={Col}>
              <FloatingLabel
                controlId="notSure"
                label="Not Sure?"
                className="mb-3"
              >
                <Form.Select
                  defaultValue="Choose..."
                  name="not_sure"
                  onChange={handleNotSureField}
                  value={values?.not_sure}
                >
                  <option value="1">Choose...</option>
                  {notSureData.map((item) => {
                    return (
                      <option key={item.value} value={item.value}>
                        {item.label}
                      </option>
                    );
                  })}
                </Form.Select>
              </FloatingLabel>
            </Form.Group> */}
          </Row>
          <Row className={costValues?.use_number_of_days ? "" : "d-flex"}>
            <Form.Group as={Col} md={6} controlId="state">
              <FloatingLabel controlId="state" label="State" className="mb-3">
                <Form.Select
                  name="state"
                  onChange={handleSelectState}
                  value={state}
                >
                  {stateLabors.map((item) => {
                    return (
                      <option key={item.area_name} value={item.area_name}>
                        {item.area_name}
                      </option>
                    );
                  })}
                </Form.Select>
              </FloatingLabel>
            </Form.Group>
            {!costValues?.use_number_of_days && (
              <>
                <Col style={{ alignSelf: "center", marginTop: "-15px" }}>
                  <Link to="https://www.ganarpro.com/cleanup-calculator-labor-and-daily-rate-cost-and-pricing-data/">
                    See Data
                  </Link>
                </Col>
                <Form.Group
                  as={Col}
                  md={4}
                  className="mb-3 checkbox m-0 d-flex"
                  controlId="sqft_and_units"
                >
                  <Form.Check
                    type="checkbox"
                    name="sqft_and_units"
                    label="Price Per SqFt And Units"
                    className="mb-4"
                    checked={values?.sqft_and_units}
                    onChange={handleSqftAndUnit}
                    disabled={values?.use_unit_pricing}
                  />
                </Form.Group>
              </>
            )}
            {costValues?.use_number_of_days && (
              <Form.Group as={Col} controlId="noOfDaysExpected" md={6}>
                <FloatingLabel
                  controlId="noOfDaysExpected"
                  label="Estimated days working"
                  className="mb-3"
                >
                  <Form.Control
                    name="no_of_days_expected"
                    type="text"
                    className="form-control mt-n3"
                    value={costValues?.noOfDaysExpected ?? 0}
                    onChange={handleNumberOfDaysExpected}
                    ref={estimatedDaysWorkingRef}
                  />
                </FloatingLabel>
              </Form.Group>
            )}
            {costValues?.use_number_of_days && (
              <Col style={{ alignSelf: "center" }}>
                <Link to="https://www.ganarpro.com/cleanup-calculator-labor-and-daily-rate-cost-and-pricing-data/">
                  See Data
                </Link>
              </Col>
            )}
          </Row>

          <Row className="my-5 mx-3">
            <Form.Group
              as={Col}
              md={4}
              className="mb-3 checkbox m-0 toggle-switch"
              controlId="price_per_sqft"
            >
              <Form.Check
                type="switch"
                name="price_per_sqft"
                label="Price per Sq.ft"
                className="mb-4"
                checked={values?.price_per_sqft}
                onChange={handlePricePerSqFt}
                disabled={
                  (!costValues?.use_number_of_days &&
                    !values?.use_living_unit_pricing) ||
                  values?.use_unit_pricing ||
                  values?.sqft_and_units
                }
              />
            </Form.Group>
            <Form.Group
              as={Col}
              md={4}
              className="mb-3 checkbox m-0 toggle-switch"
              controlId="useLivingUnitPricing"
            >
              <Form.Check
                type="switch"
                name="use_living_unit_pricing"
                label="Price per unit"
                className="mb-1"
                checked={values?.use_living_unit_pricing}
                onChange={handleLivingUnitPricingCheck}
                disabled={values?.use_unit_pricing || values?.sqft_and_units}
              />
            </Form.Group>

            <Form.Group
              as={Col}
              md={4}
              className="mb-3 checkbox m-0 toggle-switch"
              controlId="useNumberoFDays"
            >
              <Form.Check
                type="switch"
                name="use_number_of_days"
                label="Price per day"
                className="mb-4"
                checked={costValues?.use_number_of_days}
                onChange={handleNumberOfDaysCheck}
                disabled={values?.use_unit_pricing}
              />
            </Form.Group>

            {/* {costValues?.use_number_of_days && (
              <Form.Group as={Col} controlId="noOfDaysExpected" md={5}>
                <FloatingLabel
                  controlId="noOfDaysExpected"
                  label="Estimated days working"
                  className="mb-3"
                >
                  <Form.Control
                    name="no_of_days_expected"
                    type="text"
                    className="form-control mt-n3"
                    value={costValues?.noOfDaysExpected ?? 0}
                    onChange={handleNumberOfDaysExpected}
                  />
                </FloatingLabel>
              </Form.Group>
            )} */}
          </Row>
          <Row className="mb-5">
            {(costValues?.use_number_of_days || values?.price_per_sqft) &&
            !values?.sqft_and_units ? (
              <>
                <Form.Group as={Col}>
                  {/* <span className="calculator-tool-tip">
                    <ToolTip
                      show={400}
                      trigger="click"
                      information="The cleaning scopes vary in requirements depending on the type of building, which leads to adjustments in the square foot price. Lean more - <a target='_blank' href='https://www.ganarpro.com/support/understanding-building-usage-types'>Click here<a/>"
                    />
                  </span> */}
                  <FloatingLabel
                    controlId="projectType"
                    label={`Building project type`}
                    style={values?.use_living_unit_pricing && !values?.use_unit_pricing ? { boxShadow: 'darkgray 0px 0px 5px 0px' }:{}}
                    className="mb-3"
                  >
                    <Form.Select
                      defaultValue="Choose..."
                      name="project_type"
                      onChange={handleChange}
                      value={values?.project_type}
                    >
                      <option>Choose...</option>
                      {projectTypePricing.map((item) => {
                        return (
                          <option key={item.id} value={item.project_type}>
                            {item.project_type}
                          </option>
                        );
                      })}
                    </Form.Select>
                  </FloatingLabel>
                </Form.Group>
              </>
            ) : (
              <Form.Group as={Col} className="calculator-tool-tip-parent">
                <FloatingLabel
                  controlId="projectType"
                  label={`Building project type`}
                  style={values?.use_living_unit_pricing && !values?.use_unit_pricing ? { boxShadow: 'darkgray 0px 0px 5px 0px' }:{}}
                  className="mb-3"
                >
                  <Form.Select
                    defaultValue="Choose..."
                    name="project_type"
                    onChange={handleChange}
                    value={values?.project_type}
                  >
                    <option>Choose...</option>
                    {projectTypePricingPricePerDay.map((item) => {
                      return (
                        <option key={item.name} value={item.name}>
                          {item.name}
                        </option>
                      );
                    })}
                  </Form.Select>
                </FloatingLabel>
              </Form.Group>
            )}

            <Col xs="1" className="calculator-tool-tip-outside">
              <ToolTip information="The cleaning scopes vary in requirements depending on the type of building, which leads to adjustments in the square foot price." />
            </Col>
            <Col
              xs="2"
              style={{
                alignSelf: "center",
                marginTop: "-16px",
                marginLeft: "-10px",
              }}
            >
              <a
                target="_blank"
                href="https://www.ganarpro.com/support/understanding-building-usage-types"
                rel="noreferrer"
              >
                Learn more
              </a>
            </Col>

            {(hideLivingUnit || values?.use_living_unit_pricing) && (
              <>
                <Form.Group as={Col} controlId="noLivingUnits">
                  <FloatingLabel
                    controlId="noLivingUnits"
                    label="Number of living units"
                    className="mb-3"
                  >
                    <Form.Control
                      name="living_unit"
                      type="number"
                      className="form-control mt-n3"
                      value={values?.living_unit}
                      onChange={handleChange}
                      ref={noOfLivingUnitRef}
                      style={
                        values?.sqft_and_units
                          ? { boxShadow: "0 0 5px 0 darkgray" }
                          : values?.use_living_unit_pricing  && !values?.use_unit_pricing
                            ? { boxShadow: 'darkgray 0px 0px 5px 0px' }
                            : {} 
                      }
                    />
                  </FloatingLabel>
                </Form.Group>
              </>
            )}
          </Row>
          <Row className="my-5">
            <Col md="6">
              <Form.Group as={Col} controlId="numberOfPhases">
                <FloatingLabel
                  controlId="numberOfPhases"
                  label="Number of phases"
                  className="mb-3"
                >
                  <Select
                    id="numberOfPhase"
                    value={values?.phases}
                    isMulti
                    name="phase"
                    options={phaseData}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    onChange={handleSelect}
                  />
                  <Form.Control
                    name="no_phases"
                    type="text"
                    className="form-control mt-n3"
                    hidden={true}
                    onChange={handleChange}
                  />
                </FloatingLabel>
              </Form.Group>
            </Col>
            <Col xs="1" className="calculator-tool-tip-outside">
              <ToolTip
                information="Your scope requirement determines how many times you are required to clean the
                building. Normally Rough clean is performed first. Final clean is the majority of
                work. And fluff/ puff is after punch list and before project turnover. Final Clean is
                the official scope trade name and final clean is the only phase completed alone. "
              />
            </Col>
          </Row>
          <Accordion
            onSelect={(e) => {
              setSelectedKey(!selectedKey);
            }}
          >
            <Accordion.Item className="bg-white border-0" eventKey="0">
              <StyledAccordionHeader className="fs-1 col-md-3">
                {selectedName}
              </StyledAccordionHeader>
              <Accordion.Body>
                <Row className="mt-5">
                  <Col>
                    <Form.Group
                      as={Col}
                      className="mb-3 checkbox d-flex"
                      controlId="davisCheck"
                    >
                      <Form.Check
                        type="checkbox"
                        name="davis_checked"
                        label="Davis Bacon - Prevailing Wage"
                        checked={values?.davis_checked}
                        onChange={handleCheckBox}
                      />
                    </Form.Group>
                  </Col>
                  {values?.davis_checked && (
                    <Col>
                      <Form.Group controlId="formGridWage">
                        <FloatingLabel
                          controlId="davisBaconWage"
                          label="Increase %"
                        >
                          <Form.Control
                            name="davis_percentage"
                            onChange={handleChange}
                            value={values?.davis_percentage}
                            type="number"
                          />
                        </FloatingLabel>
                      </Form.Group>
                    </Col>
                  )}
                </Row>
                <Row className="mt-3">
                  <Col>
                    <Form.Group
                      as={Col}
                      className="mb-3 checkbox d-flex"
                      controlId="unionCheck"
                    >
                      <Form.Check
                        type="checkbox"
                        name="union_checked"
                        label="Union Requirement"
                        checked={values?.union_checked}
                        onChange={handleCheckBox}
                      />
                    </Form.Group>
                  </Col>
                  {values?.union_checked && (
                    <Col>
                      <Form.Group controlId="formGridUnion">
                        <FloatingLabel
                          controlId="UnionRequirement"
                          label="Increase %"
                        >
                          <Form.Control
                            name="union_percentage"
                            onChange={handleChange}
                            value={values?.union_percentage}
                            type="number"
                          />
                        </FloatingLabel>
                      </Form.Group>
                    </Col>
                  )}
                </Row>
                <Row className="mt-3 mb-2">
                  <Col md="4">
                    <Form.Group
                      as={Col}
                      controlId="state"
                      className="calculator-tool-tip-parent"
                    >
                      <span className="calculator-tool-tip">
                        <ToolTip information="For stories above 1, an additional price for exterior window cleaning will be included. However, the base cleaning price already covers 1st-story windows. The calculation involves multiplying the building square footage by a variable applied to each number of stories to arrive at the final result.  Add price to bid amount and display as external window cleaning price." />
                      </span>
                      <FloatingLabel
                        controlId="noStories"
                        label="No of stories"
                        className="mb-3"
                      >
                        <Form.Select
                          name="no_stories"
                          onChange={(e) => handleNoOfStories(e.target.value)}
                          value={values?.no_stories}
                        >
                          {[...Array(50).keys()].map((item, index) => {
                            return (
                              <option
                                key={item + 1}
                                value={parseFloat(index / 100)}
                              >
                                {item + 1}
                              </option>
                            );
                          })}
                        </Form.Select>
                      </FloatingLabel>
                    </Form.Group>
                  </Col>
                  <Col md="4">
                    <Form.Group as={Col} controlId="formGridCProjectCity">
                      <FloatingLabel
                        controlId="noStoriesResult"
                        label="Result"
                        className="mb-3"
                      >
                        <Form.Control
                          name="no_stories_result"
                          type="number"
                          onChange={(e) =>
                            handleNoOfStoriesResult(e.target.value)
                          }
                          value={values?.no_of_stories_result}
                        />
                      </FloatingLabel>
                    </Form.Group>
                  </Col>
                  <Col md="4">
                    <Form.Group
                      as={Col}
                      className="mb-3 checkbox"
                      controlId="formBasicCheckbox"
                    >
                      <Form.Check
                        type="checkbox"
                        name="no_stories_check"
                        label="Add external window cleaning"
                        checked={values?.no_stories_check}
                        onChange={handleNoStoriesCheck}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="mb-5">
                  <Col>
                    <Form.Group controlId="formGridStreet">
                      <FloatingLabel
                        controlId="window_panes"
                        label="# of window panes"
                        className="mb-3"
                      >
                        <Form.Control
                          name="window_panes"
                          onChange={handleChange}
                          value={values?.window_panes}
                          type="number"
                        />
                      </FloatingLabel>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group as={Col} controlId="formGridCProjectCity">
                      <FloatingLabel
                        controlId="price_per_window"
                        label="Price to clean each window"
                        className="mb-3"
                      >
                        <Form.Control
                          name="price_per_window"
                          type="number"
                          onChange={handleChange}
                          value={values?.price_per_window}
                        />
                      </FloatingLabel>
                    </Form.Group>
                  </Col>
                  <Col md="3">
                    <Form.Group as={Col} controlId="formGridCProjectCity">
                      <FloatingLabel
                        controlId="price_per_window_result"
                        label="Result"
                        className="mb-3"
                      >
                        <Form.Control
                          name="price_per_window_result"
                          onChange={handleChange}
                          readOnly
                          value={(
                            values?.window_panes * values?.price_per_window
                          ).toLocaleString()}
                        />
                      </FloatingLabel>
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="mb-4">
                  {values?.use_unit_pricing ?
                    <Col>
                      <h4>You are now utilizing Detailed Price Per Unit</h4>
                    </Col>
                    :
                    <Col>
                      <h4>Alternate Unit Pricing option: If you performed a takeoff of the unit count, get more accurate pricing. Activate with “price per unit” below</h4>
                    </Col>
                  }
                </Row>
                <Row className="my-3">
                  <Col>
                    <Form.Group controlId="formGridStreet">
                      <FloatingLabel
                        controlId="pricePerStudio"
                        label="Price per studio"
                        className="mb-3"
                      >
                        <Form.Control
                          style={values?.use_unit_pricing ? { boxShadow: 'darkgray 0px 0px 5px 0px' }:{}}
                          name="price_per_studio"
                          onChange={handleChange}
                          value={values?.price_per_studio}
                          type="number"
                        />
                      </FloatingLabel>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group as={Col} controlId="formGridCProjectCity">
                      <FloatingLabel
                        controlId="numberOfStudio"
                        label="Number of studio"
                        className="mb-3"
                      >
                        <Form.Control
                          name="no_of_unit_studio"
                          onChange={handleChange}
                          type="number"
                          value={values?.no_of_unit_studio}
                          style={values?.use_unit_pricing ? { boxShadow: 'darkgray 0px 0px 5px 0px' }:{}}
                        />
                      </FloatingLabel>
                    </Form.Group>
                  </Col>
                  <Col sm={1} style={{ marginLeft: '-5rem', zIndex: 10 }}>
                    <ToolTip information="The floor plans of a building will usually show the layout of each floor, with each living unit clearly marked." />
                  </Col>
                  <Col md="4">
                    <Form.Group as={Col} controlId="formGridCProjectCity">
                      <FloatingLabel
                        controlId="pricePerStudioResult"
                        label="Price to clean of all studio"
                        className="mb-3"
                      >
                        <Form.Control
                          name="pricePerStudioResult"
                          readOnly
                          value={(
                            values?.price_per_studio * values?.no_of_unit_studio
                          ).toLocaleString()}
                          style={values?.use_unit_pricing ? { boxShadow: 'darkgray 0px 0px 5px 0px' }:{}}
                        />
                      </FloatingLabel>
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="my-3">
                  <Col>
                    <Form.Group controlId="formGridStreet">
                      <FloatingLabel
                        controlId="pricePer1bedroom"
                        label="Price per 1 bedroom"
                        className="mb-3"
                      >
                        <Form.Control
                          name="price_per1_bed"
                          onChange={handleChange}
                          value={values?.price_per1_bed}
                          style={values?.use_unit_pricing ? { boxShadow: 'darkgray 0px 0px 5px 0px' }:{}}
                          type="number"
                        />
                      </FloatingLabel>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group as={Col} controlId="formGridCProjectCity">
                      <FloatingLabel
                        controlId="Number of 1 BR"
                        label="Number of 1 BR"
                        className="mb-3"
                      >
                        <Form.Control
                          name="no_of_unit1_bed"
                          onChange={handleChange}
                          type="number"
                          value={values?.no_of_unit1_bed}
                          style={values?.use_unit_pricing ? { boxShadow: 'darkgray 0px 0px 5px 0px' }:{}}
                        />
                      </FloatingLabel>
                    </Form.Group>
                  </Col>
                  <Col md="4">
                    <Form.Group as={Col} controlId="formGridCProjectCity">
                      <FloatingLabel
                        controlId="pricePer1bedResult"
                        label="Price to clean of all 1 BR"
                        className="mb-3"
                      >
                        <Form.Control
                          name="pricePer1bedResult"
                          readOnly
                          value={(
                            values?.price_per1_bed * values?.no_of_unit1_bed
                          ).toLocaleString()}
                          style={values?.use_unit_pricing ? { boxShadow: 'darkgray 0px 0px 5px 0px' }:{}}
                        />
                      </FloatingLabel>
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="my-3">
                  <Col>
                    <Form.Group controlId="formGridStreet">
                      <FloatingLabel
                        controlId="pricePer1bedroom"
                        label="Price per 2 bedrooms"
                        className="mb-3"
                      >
                        <Form.Control
                          name="price_per2_bed"
                          onChange={handleChange}
                          value={values?.price_per2_bed}
                          style={values?.use_unit_pricing ? { boxShadow: 'darkgray 0px 0px 5px 0px' }:{}}
                          type="number"
                        />
                      </FloatingLabel>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group as={Col} controlId="formGridCProjectCity">
                      <FloatingLabel
                        controlId="Number of 2 BR"
                        label="Number of 2 BR"
                        className="mb-3"
                      >
                        <Form.Control
                          name="no_of_unit2_bed"
                          type="number"
                          onChange={handleChange}
                          value={values?.no_of_unit2_bed}
                          style={values?.use_unit_pricing ? { boxShadow: 'darkgray 0px 0px 5px 0px' }:{}}
                        />
                      </FloatingLabel>
                    </Form.Group>
                  </Col>
                  <Col md="4">
                    <Form.Group as={Col} controlId="formGridCProjectCity">
                      <FloatingLabel
                        controlId="pricePer2bedResult"
                        label="Price to clean of all 2 BR"
                        className="mb-3"
                      >
                        <Form.Control
                          name="pricePer2bedResult"
                          onChange={handleChange}
                          readOnly
                          value={(
                            values?.price_per2_bed * values?.no_of_unit2_bed
                          ).toLocaleString()}
                          style={values?.use_unit_pricing ? { boxShadow: 'darkgray 0px 0px 5px 0px' }:{}}
                        />
                      </FloatingLabel>
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="my-3">
                  <Col>
                    <Form.Group controlId="formGridStreet">
                      <FloatingLabel
                        controlId="Price per 3 bedrooms"
                        label="Price per 3 bedrooms"
                        className="mb-3"
                      >
                        <Form.Control
                          name="price_per3_bed"
                          onChange={handleChange}
                          value={values?.price_per3_bed}
                          style={values?.use_unit_pricing ? { boxShadow: 'darkgray 0px 0px 5px 0px' }:{}}
                          type="number"
                        />
                      </FloatingLabel>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group as={Col} controlId="formGridCProjectCity">
                      <FloatingLabel
                        controlId="Number of 3 BR"
                        label="Number of 3 BR"
                        className="mb-3"
                      >
                        <Form.Control
                          name="no_of_unit3_bed"
                          type="number"
                          onChange={handleChange}
                          value={values?.no_of_unit3_bed}
                          style={values?.use_unit_pricing ? { boxShadow: 'darkgray 0px 0px 5px 0px' }:{}}
                        />
                      </FloatingLabel>
                    </Form.Group>
                  </Col>
                  <Col md="4">
                    <Form.Group as={Col} controlId="formGridCProjectCity">
                      <FloatingLabel
                        controlId="pricePer3bedResult"
                        label="Price to clean of all 3 BR"
                        className="mb-3"
                      >
                        <Form.Control
                          name="pricePer3bedResult"
                          onChange={handleChange}
                          readOnly
                          value={(
                            values?.price_per3_bed * values?.no_of_unit3_bed
                          ).toLocaleString()}
                          style={values?.use_unit_pricing ? { boxShadow: 'darkgray 0px 0px 5px 0px' }:{}}
                        />
                      </FloatingLabel>
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="my-3">
                  <Col></Col>
                  <Col></Col>
                  <Col>
                    <Form.Group
                      as={Col}
                      className="mb-3 checkbox"
                      controlId="useUnitPricing"
                    >
                      <Form.Check
                        type="checkbox"
                        name="use_unit_pricing"
                        className="d-flex align-self-center"
                        label="price per unit"
                        checked={values?.use_unit_pricing}
                        onChange={handleUseUnitPricingCheck}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Row className="my-3">
                  <Col>
                    <Form.Group controlId="formGridStreet">
                      <FloatingLabel
                        controlId="pressure_wash"
                        label="Pressure wash sq. ft"
                        className="mb-3"
                      >
                        <Form.Control
                          name="pressure_wash"
                          onChange={handleChange}
                          value={values?.pressure_wash}
                          type="number"
                        />
                      </FloatingLabel>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group as={Col} controlId="formGridCProjectCity">
                      <FloatingLabel
                        controlId="pressure_wash_price"
                        label="Price"
                        className="mb-3"
                      >
                        <Form.Control
                          name="pressure_wash_price"
                          type="number"
                          onChange={handleChange}
                          value={values?.pressure_wash_price}
                        />
                      </FloatingLabel>
                    </Form.Group>
                  </Col>
                  <Col md="3">
                    <Form.Group as={Col} controlId="formGridCProjectCity">
                      <FloatingLabel
                        controlId="pressure_wash_result"
                        label="Result"
                        className="mb-3"
                      >
                        <Form.Control
                          name="pressure_wash_result"
                          onChange={handleChange}
                          readOnly
                          value={(
                            values?.pressure_wash * values?.pressure_wash_price
                          ).toLocaleString()}
                        />
                      </FloatingLabel>
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="my-3">
                  <Col md="3">
                    <Form.Group controlId="formGridStreet">
                      <FloatingLabel
                        controlId="sq_ft_floor_work"
                        label="Sq. ft of floor work"
                        className="mb-3"
                      >
                        <Form.Control
                          name="sq_ft_floor_work"
                          onChange={handleChange}
                          value={values?.sq_ft_floor_work}
                          type="number"
                        />
                      </FloatingLabel>
                    </Form.Group>
                  </Col>
                  <Col md="2">
                    <Form.Group as={Col} controlId="formGridCProjectCity">
                      <FloatingLabel
                        controlId="no_of_coats"
                        label="# of coats"
                        className="mb-3"
                      >
                        <Form.Control
                          name="no_of_coats"
                          type="number"
                          min="0"
                          onChange={handleChange}
                          value={values?.no_of_coats}
                        />
                      </FloatingLabel>
                    </Form.Group>
                  </Col>
                  <Col md="3">
                    <Form.Group as={Col} controlId="formGridCProjectCity">
                      <FloatingLabel
                        controlId="price_per_coat"
                        label="price per coat"
                        className="mb-3"
                      >
                        <Form.Control
                          name="price_per_coat"
                          onChange={handleChange}
                          value={values?.price_per_coat}
                          type="number"
                          min="0.01"
                          step={0.01}
                        />
                      </FloatingLabel>
                    </Form.Group>
                  </Col>
                  <Col md="3">
                    <Form.Group as={Col} controlId="flooring_price">
                      <FloatingLabel
                        controlId="flooring_price"
                        label="Extra flooring service price"
                        className="mb-3"
                      >
                        <Form.Control
                          name="flooring_price"
                          value={(
                            values?.price_per_coat *
                            values?.no_of_coats *
                            values?.sq_ft_floor_work
                          ).toLocaleString()}
                          readOnly
                        />
                      </FloatingLabel>
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="my-3">
                  <Col></Col>
                  <Col></Col>
                  <Col>
                    <Form.Group
                      as={Col}
                      className="mb-3 checkbox"
                      controlId="useUnitPricing"
                    >
                      <Form.Check
                        type="checkbox"
                        name="add_flooring"
                        className="d-flex align-self-center"
                        label="add flooring"
                        checked={values?.add_flooring}
                        onChange={handleAddFlooringCheck}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Col}
                      className="mb-3 checkbox d-flex"
                      controlId="emergencyPricing"
                    >
                      <Form.Check
                        type="checkbox"
                        name="emergency_pricing"
                        label="Emergency pricing(Add 10%)"
                        checked={values?.emergency_pricing}
                        onChange={handleClick}
                      />
                      <span className="">
                        <ToolTip information="Also known as surge pricing or dynamic pricing, refers to a pricing strategy where the cost of a product or service increases during times of high demand or urgent need. It is commonly applied in situations where there is a sudden increase in demand or when resources are limited." />
                      </span>
                    </Form.Group>
                  </Col>
                </Row>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </>
      )}
    </StyledForm>
  );
};
export default CalculatorBidAmount;
