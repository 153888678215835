import React, { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { Col, Form, Row, Table, Container, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { fetchProjectsTables } from "../../store/actions/projects/projects.action";
import {
  DatatableWrapper,
  PaginationOptions,
  Pagination,
  TableHeader,
  TableBody,
} from "react-bs-datatable";
import { isSubscriptionActive } from "../../utils/helpers/helper";
import Spinner from "../../components/spinner/Spinner";
import styled from "styled-components";
import { PhaseFilters } from "../../components/tableFilterDropdown/PhaseFilter";
import MultiStateDropdown from "../../components/MultiStateDropdown";
import MultiStateSelection from "../../components/MultiStateSelection";
import { toastError } from "../../utils/helpers/helper";
import { getStateLaborPricingList } from "../../store/actions/mortgage_calculator/mortgage_calculator.actions";
import "./index.css";
import SubscriptionModal from "../../components/subscriptionModal";
import LoginModal from "../../components/LoginModal";
import {
  addPageFavorite,
  createPageView,
  fetchPageFavorite,
  removePageFavorite,
} from "../../store/actions/users/users.actions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar as solidStar } from "@fortawesome/free-solid-svg-icons";
import { faStar as regularStar } from "@fortawesome/free-regular-svg-icons";
import { useFeatureAccess } from "../../hooks/useFeatureAccess";
import { DateTag } from "../../components/DateTagProjectTable/DateTag";
import { Link } from "react-router-dom";

const DateFormat = (lastImport) => {
  // Convert the datetime string to a JavaScript Date object
  const lastImportDate = new Date(lastImport);

  // Format the date and time in the desired format
  const options = {
    month: "numeric",
    day: "numeric",
    year: "2-digit",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
    timeZone: "America/New_York",
  };
  const formattedDate = lastImportDate
    .toLocaleString("en-US", options)
    .toLowerCase();

  return formattedDate;
};
export const ProjectTable = () => {
  const [paymentModalShow, setPaymentModalShow] = useState(false);
  const [loginModalShow, setLoginModalShow] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { free_mode_count } = useSelector((state) => state.userFreeModeCount);
  const { user: profile } = useSelector((state) => state.userDetails);
  let { projects, loading, count, projects_info, error } = useSelector(
    (state) => state.projects
  );
  const { stateLabors } = useSelector((state) => state.stateLaborPricingList);

  const [phaseFilter, setPhaseFilter] = useState("active");
  const [selectedState, setSelectedState] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(
    parseInt(localStorage.getItem("rowsPerPage")) || 20
  );
  const [currentPage, setCurrentPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);
  const [projectTags, setProjectTags] = useState(
    localStorage.getItem(`tags_visibility_${user?.id}`)
      ? localStorage.getItem(`tags_visibility_${user?.id}`) == "true"
      : true
  );
  // const [sortState, setSortState] = useState({
  //   prop: "last_modified_date",
  //   order: "desc",
  // });
  const [sortState, setSortState] = useState(() => {
    const storedSort = localStorage.getItem("projectTableSort");
    return storedSort
      ? JSON.parse(storedSort)
      : { prop: "last_modified_date", order: "desc" };
  });

  const clearSort = () => {
    const defaultSort = { prop: "last_modified_date", order: "desc" };
    setSortState(defaultSort);
    localStorage.setItem("projectTableSort", JSON.stringify(defaultSort));
    let states = selectedState.map((state) => state.value).join(",");
    dispatch(
      fetchProjectsTables(
        `location=${states}&phase=${phaseFilter}&page=${currentPage}&page_size=${rowsPerPage}&sort=-last_modified_date`
      )
    );
  };

  const { hasFeatureAccess, enforceAccess } = useFeatureAccess("Projects");

  const { isFavorite, loading: fetchLoader } = useSelector(
    (state) => state.fetchPageFavorite
  );

  const price_id = process.env.REACT_APP_PROJECT_APP;
  // Create table headers consisting of 4 columns.
  const STORY_HEADERS = [
    {
      prop: "name",
      title: "Project Name",
      isSortable: true,
    },
    {
      prop: "city",
      title: "City",
      isSortable: true,
    },
    {
      prop: "state",
      title: "State",
      isSortable: true,
    },
    {
      prop: "sf_size",
      title: "Square Foot Size",
      isSortable: true,
      cell: (row) => (row.sf_size == 0 ? "" : row.sf_size),
    },
    {
      prop: "sf_size_str",
      title: "Project Size",
      isSortable: true,
    },
    {
      prop: "project_type",
      title: "Project Type",
      isSortable: true,
    },
    {
      prop: "account_name",
      title: "Company Name",
      isSortable: true,
    },
    {
      prop: "stage_name",
      title: "Phase",
      isSortable: true,
      cell: (row) => (row.status == "A" ? "Active" : "Historical"),
    },
    {
      prop: "view_button",
      title: "View Project",
      cell: (row) => (
        <Button
          className="fs-5 btn btn-light"
          style={{
            textWrap: "nowrap",
            background: "rgb(235, 235, 235)",
            color: "black",
          }}
          onClick={() => handleRowRedirect(row)}
        >
          View Project
        </Button>
      ),
    },
    projectTags && {
      prop: "estimate_date",
      title: "Estimate",
      isSortable: true,
      cell: (row) => (
        <div className="d-flex flex-row align-content-center">
          {row?.estimate_id && (
            <DateTag
              name=""
              dateTime={row.estimate_date}
              color="#249d5c"
              onClick={() =>
                navigate("/cleanup_calculator/edit/" + row.estimate_id)
              }
            />
          )}
        </div>
      ),
    },
    projectTags && {
      prop: "proposal_date",
      title: "Proposal",
      isSortable: true,
      cell: (row) => (
        <div className="d-flex flex-row align-content-center">
          {row?.proposal_id && (
            <DateTag
              name=""
              dateTime={row.proposal_date}
              color="#58a5ec"
              onClick={() => navigate("/my_proposal/edit/" + row.proposal_id)}
            />
          )}
        </div>
      ),
    },
    {
      prop: "project_tags",
      title: (
        <Button
          onClick={() => {
            setProjectTags((prevShow) => !prevShow);
            localStorage.setItem(`tags_visibility_${user?.id}`, !projectTags);
          }}
          variant="primary"
          className="px-4 w-100"
        >
          {projectTags ? "Hide" : "Show"}
        </Button>
      ),
      cell: (row) =>
        projectTags && (
          <div className="d-flex flex-row align-content-center"> </div>
        ),
    },
  ].filter(Boolean);

  // useEffect(() => {
  //   if (localStorage.getItem("locations")) {
  //     let states = localStorage.getItem("locations").split(",");
  //     setSelectedState(states.map((state) => ({ label: state, value: state })));
  //     dispatch(
  //       fetchProjectsTables(
  //         `page_size=${rowsPerPage}&location=${states.join(",")}`
  //       )
  //     );
  //   } else {
  //     dispatch(fetchProjectsTables(`page_size=${rowsPerPage}`));
  //   }
  // }, []);

  useEffect(() => {
    if (localStorage.getItem("locations")) {
      let states = localStorage.getItem("locations").split(",");
      setSelectedState(states.map((state) => ({ label: state, value: state })));
      dispatch(
        fetchProjectsTables(
          `page_size=${rowsPerPage}&location=${states.join(",")}&sort=${
            sortState.order === "asc" ? sortState.prop : "-" + sortState.prop
          }`
        )
      );
    } else {
      dispatch(
        fetchProjectsTables(
          `page_size=${rowsPerPage}&sort=${
            sortState.order === "asc" ? sortState.prop : "-" + sortState.prop
          }`
        )
      );
    }
  }, []);

  useEffect(() => {
    if (stateLabors.length === 0) {
      dispatch(getStateLaborPricingList());
    }
  }, [stateLabors.length]);

  useEffect(() => {
    if (!user) {
      setLoginModalShow(true);
    } else if (!hasFeatureAccess) {
      enforceAccess();
    }
    if (projects !== null) {
      setMaxPage(Math.ceil(count / rowsPerPage));
    }
  }, [projects, count]);

  useEffect(() => {
    if (stateLabors.length === 0) {
      dispatch(getStateLaborPricingList());
    }
  }, [stateLabors.length]);

  const handleStateChange = (e) => {
    if (e.length <= 5) {
      setSelectedState(e);
      let states = e.map((state) => state.value).join(",");
      dispatch(
        fetchProjectsTables(
          `location=${states}&phase=${phaseFilter}&page_size=${rowsPerPage}&sort=${
            sortState["order"] == "asc"
              ? sortState["prop"]
              : "-" + sortState["prop"]
          }`
        )
      );
      localStorage.setItem("locations", states);
    } else {
      toastError("You can filter only 5 States");
    }
  };

  const handlePhaseChange = (e) => {
    setPhaseFilter(e);
    let states = selectedState.map((state) => state.value).join(",");
    dispatch(
      fetchProjectsTables(
        `location=${states}&phase=${e}&page_size=${rowsPerPage}&sort=${
          sortState["order"] == "asc"
            ? sortState["prop"]
            : "-" + sortState["prop"]
        }`
      )
    );
  };

  const handleRowRedirect = (row) => {
    navigate(`/project_board/${row.url_slug}`);
  };

  // const onSortChange = useCallback(
  //   (nextSort) => {
  //     setSortState(nextSort);
  //     let states = selectedState.map((state) => state.value).join(",");
  //     dispatch(
  //       fetchProjectsTables(
  //         `location=${states}&phase=${phaseFilter}&page=${currentPage}&page_size=${rowsPerPage}&sort=${
  //           nextSort["order"] == "asc"
  //             ? nextSort["prop"]
  //             : "-" + nextSort["prop"]
  //         }`
  //       )
  //     );
  //   },
  //   [selectedState, phaseFilter, rowsPerPage, sortState]
  // );

  const onSortChange = useCallback(
    (nextSort) => {
      setSortState(nextSort);
      localStorage.setItem("projectTableSort", JSON.stringify(nextSort));
      let states = selectedState.map((state) => state.value).join(",");
      dispatch(
        fetchProjectsTables(
          `location=${states}&phase=${phaseFilter}&page=${currentPage}&page_size=${rowsPerPage}&sort=${
            nextSort.order === "asc" ? nextSort.prop : `-${nextSort.prop}`
          }`
        )
      );
    },
    [selectedState, phaseFilter, rowsPerPage, currentPage]
  );

  const onRowsPerPageChange = useCallback(
    (rowsPerPage) => {
      setRowsPerPage(rowsPerPage);
      localStorage.setItem("rowsPerPage", rowsPerPage.toString());
      let states = selectedState.map((state) => state.value).join(",");
      setCurrentPage(1);
      dispatch(
        fetchProjectsTables(
          `location=${states}&phase=${phaseFilter}&page_size=${rowsPerPage}&sort=${
            sortState["order"] == "asc"
              ? sortState["prop"]
              : "-" + sortState["prop"]
          }`
        )
      );
    },
    [selectedState, phaseFilter, sortState]
  );

  const onPaginationChange = useCallback(
    (nextPage) => {
      setCurrentPage(nextPage);
      let states = selectedState.map((state) => state.value).join(",");
      dispatch(
        fetchProjectsTables(
          `location=${states}&phase=${phaseFilter}&page=${nextPage}&page_size=${rowsPerPage}&sort=${
            sortState["order"] == "asc"
              ? sortState["prop"]
              : "-" + sortState["prop"]
          }`
        )
      );
    },
    [selectedState, phaseFilter, rowsPerPage, sortState]
  );

  const path = window.location.pathname.slice(1);

  useEffect(() => {
    const pageRecord = "Project Research";
    const type = "Project Research";

    dispatch(createPageView(pageRecord, path, type));
  }, [dispatch, path]);

  useEffect(() => {
    dispatch(fetchPageFavorite("Project Research"));
  }, [dispatch]);
  const handleFavoriteToggle = () => {
    if (isFavorite) {
      dispatch(removePageFavorite({ page_name: "Project Research" })); // Assuming page_url is the identifier
    } else {
      dispatch(
        addPageFavorite({ page_name: "Project Research", page_url: path })
      );
    }
  };

  return (
    <>
      <Container fluid style={{ paddingInline: "40px" }}>
        <br></br>
        <Row className="justify-content-md-center">
          <Col sm={8}>
            <Row>
              <Col md={3}>
                <h1 className="heading-name pb-2">Project Research</h1>
              </Col>
              {user && (
                <Col md={1}>
                  <FontAwesomeIcon
                    size={"2x"}
                    icon={isFavorite ? solidStar : regularStar}
                    style={{
                      color: isFavorite ? "gold" : "transparent",
                      stroke: isFavorite ? "" : "gold",
                      strokeWidth: isFavorite ? "0" : "20",
                      cursor: "pointer",
                    }}
                    onClick={handleFavoriteToggle}
                    className="fw-bolder"
                  />
                </Col>
              )}
            </Row>

            <p className="subheading">
              Construction project data displayed into a useable format that can
              be analyzed, queried and researched.{" "}
              <Link to="https://learn.ganarpro.com/business-suite-intro-project-research/">
                Read docs
              </Link>
            </p>
          </Col>
          <Col sm={9} className="text-start">
            {loading ? (
              <div className="spinner-overlay">
                <div className="spinner-container">
                  <Spinner />
                </div>
              </div>
            ) : user == null ? (
              <>
                <h3 className="pt-4 text-danger">
                  Login to view the projects!
                </h3>
              </>
            ) : projects == null ? (
              <>
                <h3 className="pt-4 text-danger">
                  Unable to get the projects!
                </h3>
              </>
            ) : error != null && error.messages != null ? (
              <>
                <div className="d-flex justify-content-center align-items-center">
                  {error.messages.map((messageObj, index) => (
                    <div key={index}>
                      <p className="pt-4 text-center text-danger">
                        <b>{messageObj.message}</b>
                      </p>
                    </div>
                  ))}
                </div>
              </>
            ) : (
              <>
                <DatatableWrapper
                  highlightOnHover
                  body={projects}
                  headers={STORY_HEADERS}
                  paginationOptionsProps={{
                    initialState: {
                      rowsPerPage: rowsPerPage,
                      options: [10, 20, 50, 100, 200, 500],
                    },
                  }}
                >
                  <Row className="mb-4 p-2">
                    <Col
                      xs={6}
                      sm={6}
                      lg={3}
                      className="d-flex flex-col justify-content-lg-end align-items-center justify-content-sm-start mb-2 mb-sm-0"
                    >
                      <Form.Group style={{ width: "95%" }}>
                        <Form.Label className="text-start fw-bolder">
                          State
                        </Form.Label>
                        <MultiStateSelection
                          stateLabors={stateLabors}
                          handleChange={handleStateChange}
                          selected={selectedState}
                        />
                      </Form.Group>
                      {/*<Select options={regions} />*/}
                    </Col>
                    <Col
                      xs={6}
                      sm={6}
                      lg={2}
                      className="d-flex flex-col justify-content-end align-items-end"
                    >
                      <PhaseFilters
                        value={phaseFilter}
                        onChange={handlePhaseChange}
                      />
                    </Col>
                    <Col
                      xs={3}
                      lg={2}
                      className="d-flex flex-col justify-content-lg-center align-items-center justify-content-sm-start mb-2 mb-sm-0"
                    >
                      <PaginationOptions
                        controlledProps={{
                          rowsPerPage,
                          onRowsPerPageChange,
                        }}
                        style={{ width: "100%" }}
                      ></PaginationOptions>
                    </Col>
                    <Col md={3} className="align-self-end">
                      <Row>
                        <Col>
                          <h4 className="fw-bolder">Last Updated:</h4>
                        </Col>
                        <Col>
                          <h4>{DateFormat(projects_info?.import_date)}</h4>
                        </Col>
                      </Row>

                      <Row className="justify-content-between">
                        <Col className="fw-bolder">
                          New: {projects_info?.added_count}{" "}
                        </Col>
                      </Row>
                    </Col>
                    <Col
                      md={2}
                      className="d-flex align-items-end align-self-end"
                    >
                      <Pagination
                        controlledProps={{
                          currentPage,
                          maxPage,
                          onPaginationChange,
                        }}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} sm={6} md={3} className="mb-2">
                      <Button onClick={clearSort} variant="outline-secondary">
                        Clear Sort
                      </Button>
                    </Col>
                  </Row>
                  <Table className="research-table">
                    <TableHeader
                      controlledProps={{
                        sortState,
                        onSortChange,
                      }}
                    ></TableHeader>
                    <TableBody />
                  </Table>
                </DatatableWrapper>
              </>
            )}
          </Col>
        </Row>
      </Container>
      <LoginModal
        show={loginModalShow}
        onHide={() => setLoginModalShow(false)}
        closeButton={false}
      />
      <SubscriptionModal
        backdrop="static"
        size="lg"
        show={paymentModalShow}
        dialogClassName="modal-100w"
        closeButton={false}
      />
    </>
  );
};
